import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Happy Thanksgiving!  We’re thankful for YOU, our CrossFit family!`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our Annual Thanksgiving Day wod will be at 9:00am today.  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`This workout is free to all so bring a friend!  This will be the only
class of the day. `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`*`}{`Friday we will reopen for the 10:30 class.  We will be back to
our normal schedule for the rest of the day.`}</em></strong></p>
    <p>{`4 Person Teams complete the following:`}</p>
    <p>{`1 Mile Row`}</p>
    <p>{`50 Pullups`}</p>
    <p>{`100 S2OH (135/95)`}</p>
    <p>{`150 Burpees`}</p>
    <p>{`200 Wall Balls (20/14)`}</p>
    <p>{`250 Situps`}</p>
    <p>{`1 Mile Run`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      